.neeto-ui-sidebar {
  width: 300px;
  height: 100vh;
  padding: 40px 0;
  z-index: 99997;

  @include scrollbars(0);
  background-color: $neeto-ui-gray-200;

  @include min(1440px) {
    width: 320px;
  }

  // header

  &__header {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 40px;
  }

  &__logo {
    width: 40px;
    height: 40px;

    border-radius: $neeto-ui-rounded;
    background-color: $neeto-ui-gray-800;
  }

  &__info {
    padding-left: 10px;
    width: calc(100% - 40px);

    .neeto-ui-typography {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  // links

  &__links {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
  }

  &__link {
    padding: 12px 8px;
    margin: 8px 0;

    &.mb-0 {
      margin-bottom: 0;
    }

    color: $neeto-ui-gray-600;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    border-radius: $neeto-ui-rounded;

    &-icon {
      flex-shrink: 0;
      transition: transform 0.3s ease-in-out;
      width: 40px;

      path {
        transition: all 0.3s;
      }
    }

    &-label {
      padding-left: 10px;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-sub-label {
      width: calc(100% - 56px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      color: $neeto-ui-gray-700;
      text-decoration: none;
      background-color: $neeto-ui-gray-300;
    }

    &:focus,
    &:focus-visible {
      color: $neeto-ui-gray-700;
      outline: none;
      background-color: $neeto-ui-gray-300;
      text-decoration: none;
    }

    &.active {
      background-color: $neeto-ui-white;
      color: $neeto-ui-gray-800;
      text-decoration: none;
    }
    &--button {
      &:focus {
        background-color: transparent;
      }
      &:focus-visible {
        background-color: $neeto-ui-gray-300;
      }
    }
  }

  &__link--change-log{
    outline: none !important;
  }

  &__sublink {
    padding: 8px 8px 8px 58px;
    margin: 4px 0;

    color: $neeto-ui-gray-600;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    border-radius: $neeto-ui-rounded;
    text-decoration: none;

    &:hover {
      color: $neeto-ui-gray-700;
      text-decoration: none;
    }

    &:focus,
    &:focus-visible {
      color: $neeto-ui-gray-700;
      outline: none;
      background-color: $neeto-ui-gray-300;
      text-decoration: none;
    }

    &:active,
    &.active {
      color: $neeto-ui-gray-800;
      text-decoration: none;
    }
  }

  &__footer {
    margin-top: auto;
    width: 100%;
    padding: 0 12px;

    .neeto-ui-sidebar__profile-wrapper {
      outline: none !important;
    }

    .neeto-ui-sidebar__profile {
      width: 100%;
      padding: 8px;

      background-color: $neeto-ui-white;
      border-radius: $neeto-ui-rounded-sm;
      transition: $neeto-ui-transition;
      cursor: pointer;
      box-shadow: 0px 1px 4px -1px rgba(28, 48, 74, 0.12);

      // profile content

      &-content {
        width: calc(100% - 40px);
        padding-left: 10px;
        padding-right: 30px;
        position: relative;

        .neeto-ui-typography {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      // profile dropdown

      &-drop-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        color: $neeto-ui-gray-500;
      }
    }
  }

  &.neeto-ui-sidebar--collapsed {
    padding: 20px 0;
    .neeto-ui-sidebar__profile {
      background-color: transparent;
      box-shadow: none;
      padding: 4px;
    }
    .neeto-ui-sidebar__links{
      padding-left: 8px;
      padding-right: 8px;
    }
    .neeto-ui-sidebar__header{
      padding-left: 12px;
      padding-right: 12px;
    }
    .neeto-ui-sidebar__link-icon{
      width: 32px;
    }
    .neeto-ui-sidebar__footer{
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.sidebar-featured-tooltip__content{
  border-radius: 8px !important;
  box-shadow: $neeto-ui-shadow-s !important;
}
