.neeto-ui-accordion__wrapper {
  border-bottom: thin solid $neeto-ui-gray-300;
  background-color: transparent;
  color: white;

  &--last-item {
    border-bottom: none;
  }
  .neeto-ui-accordion__item-toggle-icon {
    color: white !important;
    background: #ffd369;
  }

  .neeto-ui-accordion__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    align-items: center;
    padding: 16px 8px;
    cursor: pointer;
    transition: all 0.3s;
    color: white;

    .neeto-ui-accordion__item-handle {
      font-size: 14px;
      line-height: 16px;
      color: white;
    }

    &:focus-visible,
    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      background-color: #393e46;

      .neeto-ui-accordion__item-handle {
        color: white;
      }
    }

    &.neeto-ui-accordion__item--open {
      .neeto-ui-label {
        color: white;
      }
    }
  }

  .neeto-ui-accordion__drop {
    padding: 4px 8px 16px;
    margin-bottom: 0px;
    color: white;
  }
}
