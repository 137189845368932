.neeto-ui-pagination__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;

  .neeto-ui-pagination__item:not(.neeto-ui-pagination__item--dots) {
    margin: 0 4px;
    background-color: $neeto-ui-white;
    border: 1px solid $neeto-ui-gray-300;
    border-radius: $neeto-ui-rounded-sm;
    overflow: hidden;

    &:hover:not(.disabled, .active) {
      border-color: $neeto-ui-gray-800;
    }
  }

  .neeto-ui-pagination__item {
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: $neeto-ui-text-sm;
    font-weight: $neeto-ui-font-medium;
    color: $neeto-ui-gray-800;
    transition: $neeto-ui-transition;
    user-select: none;

    a {
      display: block;
      text-align: center;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }

    &:focus-visible {
      box-shadow: 0 0 0 3px $neeto-ui-gray-300;
    }

    &.active {
      border-color: $neeto-ui-gray-800;
    }

    &.neeto-ui-pagination__item--dots {
      background-color: transparent;
      text-align: center;
    }

    &.neeto-ui-pagination__item--navigate {
      text-align: center;
      color: $neeto-ui-gray-800;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:hover:not(.disabled),
      &:active {
        color: $neeto-ui-gray-800;
      }

      &.disabled {
        color: $neeto-ui-gray-300;

        a {
          cursor: not-allowed;
        }
      }
    }
  }
}
