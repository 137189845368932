.neeto-ui-tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: $neeto-ui-text-xs;
  font-weight: $neeto-ui-font-normal;
  padding: 2px 8px;
  line-height: 1.1;
  min-height: 20px;
  border-radius: $neeto-ui-rounded;
  color: $neeto-ui-gray-800;
  border-width: 1px;
  border-style: solid;

  &__indicator {
    border-radius: $neeto-ui-rounded-full;
    height: 8px;
    width: 8px;
    margin-right: 8px;
  }

  &__icon {
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__close {
    margin-left: 8px;
    opacity: 0.8;
    transition: $neeto-ui-transition;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &--size-large {
    padding: 4px 12px;
    font-weight: $neeto-ui-font-medium;
    font-size: $neeto-ui-text-sm;
    min-height: 26px;

    .neeto-ui-tag__icon,
    .neeto-ui-tag__close {
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .neeto-ui-tag__icon {
      margin-right: 12px;
    }

    .neeto-ui-tag__indicator {
      width: 10px;
      height: 10px;
      margin-right: 12px;
    }

    .neeto-ui-tag__close {
      margin-left: 12px;
    }
  }

  &--style-outline {
    border-color: $neeto-ui-gray-400;
    background-color: $neeto-ui-white;

    &.neeto-ui-tag--color-red {
      background-color: $neeto-ui-pastel-red;
      border-color: $neeto-ui-error;
      color: $neeto-ui-error;
    }

    &.neeto-ui-tag--color-blue {
      background-color: $neeto-ui-pastel-blue;
      border-color: $neeto-ui-secondary-indigo;
      color: $neeto-ui-secondary-indigo;
    }

    &.neeto-ui-tag--color-green {
      background-color: $neeto-ui-pastel-green;
      border-color: $neeto-ui-secondary-green;
      color: $neeto-ui-secondary-green;
    }

    &.neeto-ui-tag--color-yellow {
      background-color: $neeto-ui-pastel-yellow;
      border-color: $neeto-ui-warning;
      color: $neeto-ui-warning;
    }

    &.neeto-ui-tag--color-gray {
      background-color: $neeto-ui-gray-200;
    }
  }

  &--style-solid {
    border-color: $neeto-ui-pastel-blue;
    background-color: $neeto-ui-pastel-blue;

    &.neeto-ui-tag--color-red {
      background-color: $neeto-ui-pastel-red;
      border-color: $neeto-ui-pastel-red;
    }

    &.neeto-ui-tag--color-blue {
      background-color: $neeto-ui-pastel-blue;
      border-color: $neeto-ui-pastel-blue;
    }

    &.neeto-ui-tag--color-green {
      background-color: $neeto-ui-pastel-green;
      border-color: $neeto-ui-pastel-green;
    }

    &.neeto-ui-tag--color-yellow {
      background-color: $neeto-ui-pastel-yellow;
      border-color: $neeto-ui-pastel-yellow;
    }

    &.neeto-ui-tag--color-gray {
      background-color: $neeto-ui-gray-200;
      border-color: $neeto-ui-gray-200;
    }
  }
}
