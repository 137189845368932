.neeto-ui-switch__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .neeto-ui-switch__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .neeto-ui-label {
      margin-left: 12px;
    }

    .neeto-ui-switch__item {
      width: 44px;
      height: 24px;
      border: 2px solid transparent;
      border-radius: 20px;
      transition: $neeto-ui-transition;
      background-color: $neeto-ui-gray-200;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
        border: 0;
      }

      &.neeto-ui-switch__item--disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &.neeto-ui-switch__item--checked {
        background-color: $neeto-ui-gray-800;
      }

      .neeto-ui-switch {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        transition: all 0.3s ease-in-out;
        transform: translateX(0);
        background-color: $neeto-ui-white;
        border-radius: 16px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      }

      input:checked + .neeto-ui-switch {
        transform: translateX(20px);
      }

      &:focus-within {
        box-shadow: 0 0 0 2px $neeto-ui-white, 0 0 0 4px $neeto-ui-gray-300;
      }
    }
  }
}
