.neeto-ui-radio__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;

  .neeto-ui-radio__label {
    margin-bottom: 12px;
  }

  .neeto-ui-radio-input__error {
    margin-top: 4px;
    color: $neeto-ui-error;
    font-size: $neeto-ui-text-xs;
  }
}

.neeto-ui-radio__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .neeto-ui-radio__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .neeto-ui-label {
      margin-left: 12px;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .neeto-ui-radio[type="radio"] {
    width: 16px;
    height: 16px;
    color: $neeto-ui-gray-800;
    border: 2px solid $neeto-ui-gray-300;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:hover:not(:disabled, :checked) {
      border-color: $neeto-ui-gray-400;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:checked {
      border: black !important;
      background-color: #ffd369 !important;
    }

    &:focus,
    &:focus-visible {
      outline: transparent;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $neeto-ui-white, 0 0 0 4px $neeto-ui-gray-300;
    }
  }

  &.neeto-ui-radio__container--error {
    .neeto-ui-radio[type="radio"] {
      border-color: $neeto-ui-error;
    }
  }

  &.neeto-ui-radio__container--stacked {
    flex-direction: column;
    align-items: flex-start;

    .neeto-ui-radio__item:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
