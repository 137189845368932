.neeto-ui-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.neeto-ui-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  padding: 0 24px;
}

.neeto-ui-scrollable {
  flex-grow: 1;
  overflow-y: auto;

  &.neeto-ui-scrollable--small {
    height: calc(
      100vh - #{$neeto-ui-main-header-height} - #{$neeto-ui-sub-header-height} - #{$neeto-ui-sub-header-bottom-margin}
    );
  }

  &.neeto-ui-scrollable--large {
    height: calc(100vh - #{$neeto-ui-main-header-height});
  }

  &.neeto-ui-scrollable--viewport {
    height: 100vh;
  }
}

.neeto-ui-container--header-fixed{
  padding: 0;

  & > .neeto-ui-header,
  & > .neeto-ui-subheader,
  & > .neeto-ui-scrollable{
    padding-left: 24px;
    padding-right: 24px;
  }
}
