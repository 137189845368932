//Abstracts
@import "./abstracts/mixins";
@import "./abstracts/normalize";
@import "./abstracts/variables";

//Base
@import "./base/base";

//Utilities
@import "./utilities/animations";
@import "./utilities/helpers";

//Ant Components
@import "./venders/ant-animations";
@import "./venders/ant-spin";
@import "./venders/antd-buttons";
@import "./venders/antd-date-picker";
@import "./venders/antd-table";
@import "./venders/antd-pagination";
@import "./venders/antd-select";

//Components
@import "./components/accordion";
@import "./components/action-dropdown";
@import "./components/avatar";
@import "./components/button";
@import "./components/callout";
@import "./components/checkbox";
@import "./components/color-picker";
@import "./components/common";
@import "./components/date-picker";
@import "./components/dropdown";
@import "./components/input";
@import "./components/modal";
@import "./components/pageloader";
@import "./components/pagination";
@import "./components/pane";
@import "./components/radio";
@import "./components/select";
@import "./components/switch";
@import "./components/tab";
@import "./components/table";
@import "./components/tag";
@import "./components/tooltip";
@import "./components/spinner";

//Layouts
@import "./layout/appswitcher";
@import "./layout/common";
@import "./layout/header";
@import "./layout/menubar";
@import "./layout/sidebar";

//Tooltip animations
@import "../../node_modules/tippy.js/animations/scale-subtle.css";

// // AntDesign date picker
// @import "antd/dist/antd.css";
