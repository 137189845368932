.neeto-ui-pane {
  &__backdrop {
    position: fixed;
    z-index: 99998;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__wrapper {
    overflow: hidden;
    width: 480px;
    max-width: 100%;
    height: 100vh;

    background-color: $neeto-ui-white;

    @include viewport(tab-min) {
      width: 50%;
    }

    @include viewport(mob) {
      width: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__header {
    width: 100%;
    padding: 24px;
    padding-right: 60px;
  }

  &__body {
    width: 100%;
    height: calc(100vh - #{$neeto-ui-pane-header-height});
    padding: 0 24px;
    font-size: $neeto-ui-text-sm;
    overflow-y: auto;

    &.neeto-ui-pane__body--has-footer {
      height: calc(
        100vh - #{$neeto-ui-pane-header-height} - #{$neeto-ui-pane-footer-height}
      );
    }
  }

  &__footer {
    width: 100%;
    padding: 24px;
    background-color: $neeto-ui-white;
    min-height: $neeto-ui-pane-footer-height;

    &.neeto-ui-pane__footer__absolute {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
