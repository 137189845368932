.neeto-ui-input__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  // background-color: transparent !important;

  .neeto-ui-input__label-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important;

    .neeto-ui-input__max-length {
      color: $neeto-ui-gray-600;
    }
  }

  .neeto-ui-label {
    margin-bottom: 4px;
  }

  .neeto-ui-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: $neeto-ui-text-sm;
    transition: $neeto-ui-transition;
    border-radius: $neeto-ui-rounded-sm;
    border: thin solid $neeto-ui-gray-400;
    background-color: transparent;
    color: white;
    overflow: hidden;

    &.neeto-ui-input--textarea {
      padding: 8px;
    }

    input,
    textarea {
      width: 100%;
      background-color: transparent !important;
      color: inherit;
      border: none;
      font-size: inherit;
      box-shadow: none;
      outline: none;

      &::placeholder {
        color: $neeto-ui-gray-400;
      }

      &:focus {
        outline-color: transparent;
      }
    }

    input {
      padding: 8px;
      line-height: 1.2;
      min-height: 38px;
      background-color: transparent !important;
    }

    textarea {
      padding: 0;
      max-height: 224px;
      overflow-y: auto;
      line-height: 1.5;
    }

    &:focus-within:not(.neeto-ui-input--naked, .neeto-ui-input--error) {
      border-color: $neeto-ui-gray-800;
      box-shadow: $neeto-ui-shadow;
    }

    &:hover:not(.neeto-ui-input--naked, .neeto-ui-input--error, .neeto-ui-input--disabled) {
      border-color: $neeto-ui-gray-700;
      background-color: transparent;
    }

    &.neeto-ui-input--error {
      border-color: $neeto-ui-error;

      &:focus-within:not(.neeto-ui-input--naked) {
        box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
      }

      textarea {
        resize: none;
      }
    }

    &.neeto-ui-input--disabled {
      background-color: transparent;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }

      textarea {
        cursor: not-allowed;
      }
    }

    &.neeto-ui-input--small {
      input {
        padding: 5px 8px;
        min-height: 30px;
      }
    }

    &.neeto-ui-input--naked {
      border: none;
      box-shadow: none;

      .neeto-ui-input__prefix {
        margin-left: 0;
      }

      .neeto-ui-input__suffix {
        margin-right: 0;
      }
    }
  }

  .neeto-ui-input__prefix,
  .neeto-ui-input__suffix {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: $neeto-ui-gray-600;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .neeto-ui-input__prefix {
    margin-left: 10px;
  }
  .neeto-ui-input__suffix {
    margin-right: 10px;
  }

  .neeto-ui-input--block-add-on {
    .neeto-ui-input__prefix,
    .neeto-ui-input__suffix {
      margin: 0;
      padding: 8px 10px;
      background-color: $neeto-ui-gray-100;
    }
    .neeto-ui-input__prefix {
      border-right: thin solid $neeto-ui-gray-300;
    }
    .neeto-ui-input__suffix {
      border-left: thin solid $neeto-ui-gray-300;
    }
  }
}

.neeto-ui-input__help-text {
  margin-top: 8px;
  font-size: $neeto-ui-text-xs;
  color: $neeto-ui-gray-500;
  line-height: 1.6;
}

.neeto-ui-input__error {
  margin-top: 4px;
  font-size: $neeto-ui-text-xs;
  color: $neeto-ui-error;
}
