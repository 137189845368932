.neeto-ui-react-select__container.neeto-ui-react-select__container--error {
  .neeto-ui-react-select__control {
    border: thin solid $neeto-ui-error;
  }
  .neeto-ui-react-select__control.neeto-ui-react-select__control--is-focused {
    border: thin solid $neeto-ui-error;
    box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
  }
}

.neeto-ui-react-select__container,
.neeto-ui-react-select__menu-portal {
  width: 100%;
  font-size: $neeto-ui-text-sm;

  &.neeto-ui-react-select__container--naked {
    .neeto-ui-react-select__control {
      border: none;
      background-color: transparent;
    }

    .neeto-ui-react-select__control.neeto-ui-react-select__control--is-focused {
      border: none;
    }

    .neeto-ui-react-select__value-container {
      padding: 0;
      color: white !important;
    }

    .neeto-ui-react-select__indicator {
      padding: 0;
    }
  }

  &.neeto-ui-react-select--is-disabled {
    cursor: not-allowed;
    pointer-events: all;

    .neeto-ui-react-select__indicators {
      pointer-events: none;
    }
  }

  .neeto-ui-react-select__control.neeto-ui-react-select__control--is-disabled {
    background-color: $neeto-ui-gray-100;
    cursor: not-allowed;
  }

  .neeto-ui-react-select__control.neeto-ui-react-select__control--is-focused {
    border: thin solid $neeto-ui-gray-800;
    transition: $neeto-ui-transition;
    outline-color: transparent;
    box-shadow: $neeto-ui-shadow;
  }

  .neeto-ui-react-select__control {
    background-color: transparent !important;
    color: white !important;
    border: thin solid $neeto-ui-gray-400;
    border-radius: $neeto-ui-rounded-sm;
    background-color: $neeto-ui-white;
    min-height: 0;
    transition: border 0.3s;
  }

  .neeto-ui-react-select__value-container {
    padding: 0 8px;
    min-height: 38px;

    &.neeto-ui-react-select__value-container--is-multi {
      padding: 0 4px;
      &.neeto-ui-react-select__value-container--has-value {
        padding: 6px 12px;
      }
    }
  }

  &.neeto-ui-react-select__container--small {
    .neeto-ui-react-select__value-container {
      min-height: 30px;
      // background-color: #393e46 !important;
      background-color: #393e46;
      color: white !important;
    }
  }

  .neeto-ui-react-select__placeholder {
    color: white !important;
    font-size: inherit;
  }

  .neeto-ui-react-select__input {
    color: white !important;
    font-size: inherit;
  }

  .neeto-ui-react-select__indicator-separator {
    display: none;
  }

  .neeto-ui-react-select__indicator {
    padding: 0 4px 0 0;
    cursor: pointer;
  }

  .neeto-ui-react-select__menu {
    border-radius: 0px;
    margin: 0 0 16px;
    z-index: 20;
    color: white;

    .neeto-ui-react-select__menu-list {
      // position: relative;
      padding: 0px !important;
      border: thin solid $neeto-ui-gray-100;
    }

    .neeto-ui-react-select__option {
      color: white;
      font-size: inherit;
      cursor: pointer;
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      // word-break: break-word;
      background-color: $neeto-ui-gray-800;
    }

    .neeto-ui-react-select__option--is-focused {
      background-color: $neeto-ui-gray-100;
      color: gray !important;
    }

    .neeto-ui-react-select__option--is-disabled {
      color: $neeto-ui-gray-200;
      cursor: not-allowed;
    }

    .neeto-ui-react-select__option--is-selected {
      background-color: white;
      color: gray !important;
    }

    .neeto-ui-react-select__fixed-option {
      width: 100%;
      font-size: inherit;
      border-top: thin solid $neeto-ui-gray-100;
      padding: 2px 0;
      cursor: pointer;

      a {
        width: 100%;
        display: inline-block;
        color: white;
        transition: $neeto-ui-transition;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 8px 12px;
      }

      &:hover {
        a {
          color: white !important;
          text-decoration: none;
        }
      }
    }
  }

  .neeto-ui-react-select__multi-value {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $neeto-ui-gray-100;
    border: thin solid $neeto-ui-gray-800;
    border-radius: 3px;
    margin: 3px 6px 3px 0;
    .neeto-ui-react-select__multi-value__label {
      line-height: 24px;
      margin: 0 4px 0 0;
    }
  }

  .neeto-ui-react-select__multi-value__remove {
    cursor: pointer;
    transition: $neeto-ui-transition;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: $neeto-ui-gray-800;
  }
}

.neeto-ui-react-select__menu-portal {
  .neeto-ui-react-select__menu {
    margin: 0;
  }
}
