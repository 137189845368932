@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  to {
    transform: scaleX(0.8);
    transform-origin: 100% 0;
    opacity: 0;
  }
}
