@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

:root {
  --h1: 2.488rem;
  --h2: 1.875rem;
  --h3: 1.6rem;
  --h4: 1.4rem;
  --h5: 1.2rem;
  --h6: 1rem;
  --p: 1rem;
  --sm: .875rem;
  --xs: .75rem;

  --flexible-base: 0.5rem;
  --flexible-base2: 0.5rem;
  --text-color: #393E46;
  --theme-color: #ffd369;
}

@media (min-width: 768px) {
  :root {
    --h1: 3.052rem;
    --h2: 2.441rem;
    --h3: 1.953rem;
    --h4: 1.563rem;
    --h5: 1.275rem;
    --h6: 1rem;
    --p: 1rem;
    --sm: .875rem;
    --xs: .75rem;

    --flexible-base: 0.625rem;
    --flexible-base2: 0.75rem;
  }
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: var(--p);
  color: #fff;
  line-height: 1.5;
}

a {
  color: var(--theme-color);
  transition: all, .2s ease-in;

  &:hover,
  &:focus {
    color: var(--text-color);
    background-color: var(--theme-color);
    border-radius: .25em;
    outline: none;
  }
}

.content-padding-left {
  padding-left: 2em;
}

.ellipsis{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.datePicker .b5.b6.b7.b8 {
  /* Your custom styles here */
  border-color: #c2c8cc !important;
  // color: "white";
  // border: "1px solid #c2c8cc";
}

.wiggle {
  animation: wiggle 0.4s linear;
}

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25%, 75% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
}

@for $i from 1 through 6 {

  h#{$i},
  .h#{$i} {
    font: 600 var(--h#{$i}) / 1.2 'Nunito Sans',
    sans-serif;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-sm {
  font-size: .875rem;
}

.text-yellow {
  color: #ffd369;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

p:not(:last-child) {
  margin-bottom: .5rem;
}

img {
  object-fit: contain;
}

.container {
  width: 100%;
  padding-left: calc(2 * var(--flexible-base));
  padding-right: calc(2 * var(--flexible-base));
  margin: auto;
}

.container-l {
  max-width: 1024px;
}

.container-xl {
  max-width: 1280px;
}

.container-fluid {
  max-width: unset;
}

.black {
  color: var(--text-color);
}

.navbar__text-banner {
  text-align: center;
  width: 100%;
  padding: 0 1rem 0.5rem 3rem;
  opacity: 1;
  transition: all, .2s ease-in;

  &-text {
    font-size: 0.84375rem;
    transition: all, .2s ease-in;
  }
}

.logo-mobile {

  &__link {
    display: block;
    padding: 0;

    &_logo:hover {
      background-color: transparent;
    }
  }

  &__img {
    width: 10rem;
    transition: width, .2s ease-in;
  }

  &__wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-block: 1rem .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color, .2s ease-in;

    &_active {
      background-color: #393E46;

      .logo-mobile__img {
        width: 8rem;
      }

      .navbar__text-banner {
        height: 0;
        padding: 0;
        opacity: 0;
      }
    }
  }
}

.logo {
  padding: .5rem 0;

  &__img {
    height: 3rem;
    width: 140px;
  }
}

.nav__header {
  display: none;
  background-color: #393e46;
  padding-block: .75em;
}

.nav__links {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.nav__links>a,
.nav__dropdown-title,
.nav__dropdown a {
  color: var(--text-color);
  font-size: .93rem;
  font-weight: 600;
  padding: .5em .75em;
  border-radius: .25em;
  cursor: pointer;
  display: block;
  transition: all, .25s ease-in;
}

.nav__dropdown:hover,
.nav__dropdown:focus {
  .visually-hidden {
    width: 7.25rem;
    height: auto;
    clip: unset;
    clip-path: unset;
    background: var(--theme-color);
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px -2px #000a;
    border-radius: .25rem;
  }
}

.nav__dropdown-li a {
  border-radius: 0;

  &:hover,
  &:focus {
    color: var(--theme-color);
    background-color: var(--text-color);
  }
}

.nav-mobile {
  background: #393e46;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -20rem;
  z-index: 8;//51;
  width: 20rem;
  height: 100vh;
  padding: 1.5rem 1rem;
  transition: left, 0.2s cubic-bezier(.42, 0, .98, .31);

  &_visible {
    left: 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;

    a {
      display: block;
      padding: .5rem;
    }
  }

  &__dropdown {

    a {}
  }

  &__dropdown-title {
    display: block;
    padding: 0.5rem;
  }

  &__dropdown-sublist {
    display: flex;
    gap: 1rem;
  }

  &__dropdown-li {
    flex: 1 1 50%;
  }
}

.hamburger-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7; // z-index: 50;
}

.hamburger-inner {
  position: relative;
  z-index: 2;
}

.hamburger {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: .5rem;
}

@media (min-width: 1024px) {

  .hamburger-wrap,
  .nav-mobile {
    display: none;
  }

  .nav__header {
    display: block;
  }
}

.footer {
  color: var(--text-color);
  padding-top: calc(1.5 * var(--flexible-base));
  padding-bottom: calc(1.5 * var(--flexible-base));
  position: relative;
  z-index: 1;

  &__inner {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    p {
      margin-bottom: 0;
    }
  }

  &__links-wrap {
    display: flex;
    justify-content: center;
    gap: 2em;

    a {
      color: inherit;
    }
  }
}

@media (max-width: 1023px) {
  .main {
    padding-top: 8.5rem;
  }
}

.main_inner {
  padding-top: 8rem;
}

@media (min-width: 1024px) {
  .main_inner {
    padding-top: 4rem;
  }
}

@media (min-width: 768px) {
  .footer {
    &__inner {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__text-wrap {
      flex: 1;
      display: flex;
    }

    &__links-wrap {
      gap: 1em;
      margin-left: 1em;
    }
  }
}

.pickup-form-wrap {
  padding: 8rem 0 4.5rem;
  max-width: 35rem;
  margin: auto auto;
  position: relative;
  // background-position: 30% 50%;
  background-size: 300% auto;
}

.pickup-form {
  width: 85%;
  max-width: 35rem;
  margin: auto;
  position: relative;
  z-index: 1;

  .ant-picker-input>input::placeholder {
    color: #fff;
  }

  &__btn-add-stop-wrap {
    display: flex;
    justify-content: center;
    margin: 1.125rem 0 0 !important;
  }

  &__btn-add-stop {
    button {
      padding: 0.625rem 0.75rem;

      i {
        padding-right: .5em;
      }
    }
  }
}

@media (min-width: 1024px) {
  .pickup-form {
    &__btn-add-stop-wrap {
      justify-content: center;
      // position: absolute;
      // right: -9.25rem;
      // top: -1px;
      margin: 0 !important;
    }
  }
}

.home-header-bottom {
  text-align: center;
  padding: 4.5rem 0 7rem;
  position: relative;
}

.header-cards-wrap {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 4rem;
}

.header-card {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    width: 4.25rem;
    height: 4.25rem;
    margin-bottom: 2.5rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__text {
    max-width: 16em;
  }
}

@media (max-width: 767px) {
  .pickup-form-wrap-bg{
    background: url("../../src/assets/home-banner.svg") center center/cover no-repeat;
  }
}
@media (min-width: 768px) {
  .home-header {
    background: url("../../src/assets/home-banner.svg") center center/cover no-repeat;
    position: relative;
  }

  .home-header-bottom {
    padding-top: 2.5rem;

    >* {
      position: relative;
    }
  }

  .pickup-form-wrap {
    padding: 6.5rem 0 4.5rem;
    background: unset;
  }

  .header-cards-wrap {
    flex-direction: row;
    justify-content: space-between;
    gap: 2.5rem;
  }

  .header-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(104, 104, 104, 0.19);
    padding: 1rem;
    border-radius: 1.5rem;
  }

  .header-card__text {
    max-width: 19em;
  }
}

// @media (max-width: 767px){
// .home-header {
//   background: url("../../src/assets/home-banner.svg") center center/cover no-repeat;
//   position: relative;
// }
// }

@media (min-width: 1024px) {
  .header-card {
    padding: 2rem;
    border-radius: 2rem;
  }
}

.testimonials {
  color: var(--text-color);
  text-align: center;
  padding: 2.5rem calc(2 * var(--flexible-base));

  &__title {
    margin-bottom: .875rem;
  }

  &__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 38rem;
    width: 100%;
    height: 100%;
    min-height: 200px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  &__name {
    font-style: italic;
    font-weight: 700;
    margin-bottom: 1em;
    position: relative;
    display: inline-block;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: .6em;
      width: 1em;
      height: 1px;
      background: currentColor;
    }

    &::after {
      right: -1.5em;
    }

    &::before {
      left: -1.5em;
    }
  }

  &__text {
    max-width: 48rem;
    padding: 0 8%;
  }

  button i {
    filter: drop-shadow(1px 1px 1px #00000040);
  }
}

@media (min-width: 768px) {
  .testimonials {
    padding-block: 5rem;

    &__title {
      margin-bottom: 2.5rem;
    }
  }
}

.home-services {

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    text-align: center;
    padding-block: 4.5rem 9rem;
  }

  &-cards {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  &-card__image {
    width: 100%;
    max-width: 30rem;
    margin-bottom: 1.25rem;
    border-radius: 6px;
  }
}

@media (min-width: 768px) {
  .home-services__inner {
    padding-block: 5rem 14rem;
  }
}

@media (min-width: 1024px) {
  .home-services {
    &__inner {
      gap: 4rem;
    }

    &-cards {
      flex-direction: row;
      gap: 3.5rem;
    }

    &-card {
      &__image {
        margin-bottom: 1.75rem;
      }
    }
  }
}

.download {
  background: #eee;
  padding-block: 2.5rem;

  &__image {
    width: 8.5rem;
    position: absolute;
    right: calc(2 * var(--flexible-base));
    bottom: 50%;
  }

  &__inner {
    position: relative;
  }

  &__text-wrap {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__links-wrap {
    display: flex;
    gap: 1rem;
  }

  &__link {

    img {
      width: 7rem;
    }
  }
}

@media (min-width: 768px) {
  .download {
    padding-block: 5rem;

    &__image {
      width: 17rem;
      bottom: 25%;
    }

    &__title {
      font-size: var(--h1);
      margin-bottom: 1.5rem;
    }

    &__links-wrap {
      gap: 1rem;
    }

    &__link {

      img {
        width: 9.375rem;
      }
    }
  }
}

.agent-program {
  padding-block: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &__text-wrap {
    position: relative;
    padding-inline: 2.5rem;

    &::before {
      content: "";
      background: var(--theme-color);
      width: .5rem;
      height: 6.5rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__button {
    text-align: center;
    display: block;
    max-width: 16rem;
    margin-top: 1.125rem;
  }
}

@media (min-width: 768px) {
  .agent-program {
    flex-direction: row;
    gap: 3.5rem;
    padding-block: 5rem;

    &__text-wrap {
      flex: 1;
      padding-inline: 2.5rem 0;
    }
  }
}


.faq {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: .5rem;

  .neeto-ui-accordion__wrapper {
    background: #eee;
    border-radius: .5rem;
  }

  .neeto-ui-accordion__wrapper .neeto-ui-accordion__item {
    font-weight: 700;
    background-color: var(--theme-color);
    padding: .5rem 1.125rem;
    border-radius: 0.5rem;
    box-shadow: 0px 6px 18px 0px #0D33201A;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .neeto-ui-accordion__wrapper .neeto-ui-accordion__item .neeto-ui-accordion__item-handle {
    color: #000;
  }

  .neeto-ui-accordion__wrapper .neeto-ui-accordion__drop {
    color: #000;
    font-size: var(--p);
    padding: 1.125rem;
  }
}


@media (min-width: 768px) {
  .faq {
    flex: 1;
    gap: 1.25rem;

    .neeto-ui-accordion__wrapper {
      background: #eee;
      border-radius: .5rem;
    }

    .neeto-ui-accordion__wrapper .neeto-ui-accordion__item {
      padding: .75rem 1.25rem;
    }
  }
}



.banner-header {
  background: url("../assets/service-header.png") center/cover no-repeat;
  padding-bottom: 33.75%;
  position: relative;

  h1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 85%;
    display: flex;
    justify-content: center;
  }
}

.service__subtitle-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 0 calc(8 * var(--flexible-base));
}

.service-card {
  padding-bottom: calc(8 * var(--flexible-base2));
  position: relative;

  >div {
    position: relative;
    z-index: 1;
  }

  &__image {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto 2rem;
    border-radius: 1rem;
  }
}

@media (min-width: 1024px) {
  .service__subtitle-wrap {
    padding-block: calc(6 * var(--flexible-base)) calc(8 * var(--flexible-base));
  }

  .service-card {
    display: flex;
    align-items: center;
    padding: 0;

    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    &__image {
      display: none;
    }

    &__text-wrap {
      display: flex;
      align-items: center;
      gap: 3rem;
      text-align: left;

      &>div,
      &::after {
        flex: 1 1 50%;
      }
    }

    &_airport {
      background: unset;

      .service-card__text-wrap {

        &::after {
          display: block;
          background: url("../assets/airport.webp") center/cover no-repeat;
          content: "";
          width: 100%;
          height: 300px;
          border-radius: .5rem;
        }
      }
    }

    &_meet {
      background: unset;

      .service-card__text-wrap {
        flex-direction: row-reverse;

        &::after {
          display: block;
          background: url("../assets/meet.webp") center/cover no-repeat;
          content: "";
          width: 100%;
          height: 20rem;
          border-radius: .5rem;
        }
      }
    }
  }
}

.airport-list-description {
  text-align: center;
  margin: auto;
}

.airport-list {
  --auto-grid-min-size: 18.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1.5rem;
}

.inner-page {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .airport-list {
    grid-gap: 3rem;
  }
}

@media (max-width: 1023px) {
  .banner-header {
    margin-top: 8.5rem;
    background: unset;
    padding: unset;

    h1 {
      position: static;
    }
  }

  .inner-page {
    // padding-top: 8.5rem;
  }
}


@media (min-width: 1024px) {
  .address-data {
    left: 50%;
  }
}



.dispatcher-advantages-list {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;

  &__li {
    display: flex;
    font-weight: 400;

    i {
      font-size: 1.5em;
      margin: -.25em 1em 0 0;
    }
  }
}

.agents-first-col,
.dispatcher-first-col {
  position: relative;
  z-index: 1;
}

.btn-register {
  font-weight: 700;
  padding: .625em 2.5em;
}

@media (max-width: 1023px) {

  .agents-first-col,
  .dispatcher-first-col {
    width: 100vw;
    padding-top: 8.5rem;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #303030db;
      z-index: -1;
    }
  }
}

@media (min-width: 1024px) {
  .agents-main {
    position: relative;
  }

  .dispatcher-main {
    position: relative;
  }

  // .agents-main::after,
  // .dispatcher-main::after {
  //   content: "";
  //   width: 50%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background: #303030db;
  //   z-index: 0;
  // }

  // .agents-first-col,
  // .dispatcher-first-col {
  //   position: sticky;
  //   top: 0;
  // }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

// contact us

.content-wrap {
  width: 100%;
  max-width: 40rem;
  margin-right: auto;
  margin-left: auto;
}

.address {
  line-height: 1.5;
  background: url("../assets/contact-us.webp") center right/cover no-repeat;
  padding-block: 2.5rem;

  &__text-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  address {
    font-style: normal;
  }
}

@media (min-width: 1024px) {
  .address {
    padding-block: 4rem;
  }
}


.inner-page-tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  >div {
    flex: 1;
    width: 100%;
    max-width: 28.75rem;
  }
}

.vehicle-card {
  &__stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: .5em;
  }

  &__button {
    margin-top: 1rem;
  }
}

.journey-details-table {
  margin-top: 0 !important;

  th {
    vertical-align: top;
    text-align: left;
    min-width: 8rem;
    padding-right: 2rem;
    padding-top: .5rem;
  }

  td {
    vertical-align: top;
    padding-top: .625rem;
  }

  i {
    position: relative;
    top: .125rem;
  }

  &__icon {
    width: 1em;
    margin-right: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .inner-page-tab-content {
    flex-direction: row;
    align-items: flex-start;
  }
}

.authentication-page {
  background: url("../assets/login-mobile.webp") center/cover no-repeat;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: #00000064;
  }
}

.authentication {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;

  &__box {
    background: #000000ad;
    max-width: 35rem;
    padding: 1.5rem 1rem;
    margin: auto;
    border-radius: 1rem;
  }

  &__heading-wrap {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__button-wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }

  &__submit {
    font-size: var(--h6);
    font-weight: 600;
    padding: .75em;
    display: flex;
    justify-content: center;
  }

  &__alt-link {
    align-self: center;
  }
}

@media (min-width: 640px) {
  .authentication {
    &-page {
      background-image: url("../assets/login.webp");
    }

    &__box {
      padding: 2.5rem 2rem;
      border-radius: 1.5rem;
    }
  }
}

.phone-number-wrap .neeto-ui-react-select__container {
  width: 160px;
}

.whatsapp-link {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 7;
  // z-index: 50;
  width: 2.5rem;

  &:hover,
  &:focus {
    background: transparent;
  }

  @media (min-width: 768px) {
    .whatsapp-link {
      width: 3.5rem;
    }
  }
}

.payment {
  text-align: center;

  h1 {
    margin-bottom: .5em;
  }

  &__button-options {
    display: flex;
    justify-content: space-between;
    max-width: 20rem;
    margin: var(--h4) auto 0;

    a {
      color: var(--text-color);
      background-color: var(--theme-color);
      width: 8em;
      padding: .5em 1em;
      border-radius: .25em;

      &+a {
        color: var(--theme-color);
        background-color: var(--text-color);
      }
    }
  }
}

.new-line {
  white-space: pre-line;
}

.datePickerWrap>div>div,
.datePickerWrap>div>div>div {
  background-color: transparent;
  z-index: inherit !important
}



.datePickerWrap>div {
  // border: 1px solid #c2c8cc;
  border-radius: 2px;
  // z-index: 53 !important
}

// bookingitem

.booking-item-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.booking-item {
  border: 1px solid #fff;
  border-radius: .5rem;

  &__header {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #fff;

    >div:first-child {
      flex: 1;
    }
  }

  &__body {
    padding: 1rem;
  }

  &__arrow-wrap-mobile {
    display: flex;
  }

  &__arrow-wrap-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .booking-item-list {
    gap: 0;
    border: 1px solid #fff;
    border-radius: .5rem;
  }

  .booking-item {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }

    &__header {
      width: 20%;
      min-width: 9rem;
      padding-right: 0;
      border-bottom: 0;
    }

    &__body {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex: 1;

      >div {
        margin-bottom: 0;
      }
    }

    &__location-wrap {
      flex: 1;
    }

    &__location {
      margin-bottom: 0;
    }

    &__price-time-wrap {
      min-width: 9rem;
      display: grid;
      align-items: center;
      grid-template-columns: 3rem 1fr;
      gap: 1rem;
    }
  }
}

@media (min-width: 1072px) {
  .booking-item {
    &__location-wrap {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__location {
      flex: 1;
    }

    &__arrow-wrap-mobile {
      display: none;
    }

    &__arrow-wrap-desktop {
      display: flex;
    }
  }
}

.booking-tabs {
  width: calc(100% - (4 * var(--flexible-base)));
}

.booking-tabs-wrap {

  .headroom--unpinned {
    top: 6.75rem !important;
    background: #393e46;
  }

  .headroom--scrolled {
    top: 4.75rem !important;
    transform: translate3d(0px, 0px, 0px) !important;
    background: #393e46;
  }
}

@media (min-width: 768px) {
  .booking-tabs {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .booking-tabs-wrap {

    .headroom--unpinned {
      top: 2rem !important;
    }

    .headroom--scrolled {
      top: 7.75rem !important;
    }
  }
}