.neeto-ui-checkbox__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent !important;
  align-items: flex-start;
  color: transparent !important;
  flex-grow: 1;

  .neeto-ui-checkbox__container {
    display: flex;
    color: transparent !important;
    background-color: transparent !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid white;
    .neeto-ui-label {
      margin-left: 12px;
    }

    .neeto-ui-checkbox[type="checkbox"] {
      width: 16px;
      height: 16px;
      color: transparent !important;
      border: 2px solid transparent;
      border-radius: $neeto-ui-rounded-sm;
      background-color: transparent !important;

      &:not(:disabled) {
        cursor: pointer;
        color: transparent !important;
        background-color: transparent !important;
      }

      &:hover:not(:disabled, :checked) {
        color: transparent !important;
        background-color: transparent !important;
      }

      &:checked {
        border: none !important;
        color: transparent !important;
        background-color: transparent !important;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:focus,
      &:focus-visible {
        outline: transparent;
        background-color: transparent !important;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px $neeto-ui-white, 0 0 0 4px $neeto-ui-gray-300;
      }
    }
  }
}

// ant design checkbox override

.ant-checkbox {
  [type="checkbox"] {
    width: 16px;
    height: 16px;
    color: transparent;
    border: 2px solid $neeto-ui-gray-300;
    border-radius: $neeto-ui-rounded-sm;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:hover:not(:disabled, :checked) {
      border-color: $neeto-ui-gray-400;
    }

    &:checked {
      border: none !important;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:focus,
    &:focus-visible {
      outline: transparent;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $neeto-ui-white, 0 0 0 4px $neeto-ui-gray-300;
    }
  }
  .ant-checkbox-inner {
    display: none;
  }
}
