.neeto-ui-tab__wrapper {
  width: 100%;
  border-bottom: 2px solid $neeto-ui-gray-300;

  &--size-large {
    .neeto-ui-tab {
      font-size: $neeto-ui-text-xl;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &--underline-none {
    border-bottom: none;
    .neeto-ui-tab {
      &::before {
        display: none;
      }
    }
  }
}

.neeto-ui-tab {
  color: $neeto-ui-gray-500;
  font-weight: $neeto-ui-font-semibold;
  font-size: $neeto-ui-text-sm;
  line-height: 1;
  padding: 12px 8px;
  transition: $neeto-ui-transition;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active{
    text-decoration: none;
    outline: none;
  }

  &:focus,
  &:focus-visible{
    color: $neeto-ui-gray-800;
  }

  &__icon {
    margin-right: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: $neeto-ui-gray-600;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: transparent;
    left: 0;
    right: 0;
    bottom: -2px;
    transition: $neeto-ui-transition;
  }

  &.active {
    color: $neeto-ui-gray-800;

    &:before {
      background-color: $neeto-ui-gray-800;
    }
  }
}
