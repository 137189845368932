.neeto-ui-menubar__wrapper {
  background-color: $neeto-ui-gray-100;
  flex-shrink: 0;
  overflow: hidden;

  .neeto-ui-menubar__container {
    width: 324px;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;

    .neeto-ui-menubar__title {
      margin-bottom: 16px;
    }

    // search

    .neeto-ui-menubar__search {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 4px;
    }

    // subtitle

    .neeto-ui-menubar__subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;

      &-actions {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    // block

    .neeto-ui-menubar__add-new-wrap {
      padding: 7px 8px;
      margin-bottom: 8px;
    }

    .neeto-ui-menubar__block {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 8px;
      margin-bottom: 8px;
      transition: all 0.3s;
      cursor: pointer;
      color: $neeto-ui-gray-600;
      border-radius: $neeto-ui-rounded-sm;

      &:hover,
      &:focus,
      &:focus-visible,
      &:active {
        background-color: $neeto-ui-gray-200;
        text-decoration: none;
        outline: none;
      }

      &--active {
        background-color: $neeto-ui-white;
        color: $neeto-ui-gray-800;
        box-shadow: 0px 1px 4px -1px rgba(28, 48, 74, 0.12);

        &:hover,
        &:focus,
        &:focus-visible,
        &:active {
          background-color: $neeto-ui-white;
        }
      }

      .neeto-ui-menubar__block-label {
        display: flex;
        align-items: center;
      }
      .neeto-ui-menubar__block-icon {
        margin-right: 4px;
      }
    }

    // item

    .neeto-ui-menubar__item {
      padding: 12px;
      width: 100%;
      margin-bottom: 8px;
      transition: all 0.3s;
      text-align: left;
      cursor: pointer;
      border-radius: $neeto-ui-rounded-sm;

      &-header{
        color: $neeto-ui-gray-700;
      }

      &-item-desc{
        color: $neeto-ui-gray-500;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:active {
        background-color: $neeto-ui-gray-200;
        outline: none;
      }

      &--active {
        background-color: $neeto-ui-white;
        color: $neeto-ui-gray-800;
        box-shadow: 0px 1px 4px -1px rgba(28, 48, 74, 0.12);

        &:hover,
        &:focus,
        &:focus-visible,
        &:active {
          background-color: $neeto-ui-white;
        }
      }
    }
  }
}
