.neeto-ui-btn {
  display: inline-flex;
  align-items: center;
  font-size: $neeto-ui-text-sm;
  font-weight: $neeto-ui-font-medium;
  border-radius: $neeto-ui-rounded-sm;
  line-height: 1.2;
  transition: $neeto-ui-transition;
  position: relative;
  border: none;
  cursor: pointer;
  padding: 0;
  user-select: none;
  padding: 4px 8px;
  min-height: 28px;
  letter-spacing: -0.15px;
  outline: none;

  &--icon-only {
    padding-left: 6px;
    padding-right: 6px;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &_md {
    padding: .75em;
  }

  .neeto-ui-btn__icon:last-child:not(:first-child),
  .neeto-ui-btn__spinner:last-child:not(:first-child) {
    margin-left: 8px;
  }
}

.neeto-ui-btn--icon-left {
  flex-direction: row-reverse;
  .neeto-ui-btn__icon:last-child:not(:first-child),
  .neeto-ui-btn__spinner:last-child:not(:first-child) {
    margin-right: 8px;
    margin-left: 0;
  }
}

.neeto-ui-btn__icon,
.neeto-ui-btn__spinner,
.neeto-ui-btn__spinner-wrapper {
  color: inherit;
  svg {
    color: inherit !important;
  }
}

// width
.neeto-ui-btn--width-full {
  width: 100% !important;
  justify-content: center;
}

// size
.neeto-ui-btn--size-large {
  font-size: $neeto-ui-text-base;
  padding: 6px 12px;
  min-height: 32px;

  &.neeto-ui-btn--icon-only {
    padding-left: 8px;
    padding-right: 8px;
  }
}

// styles
.neeto-ui-btn--style {
  &-primary {
    color: black;
    background-color: #ffd369;

    &:hover:not(:disabled),
    &:active {
      color: $neeto-ui-white;
      background-color: $neeto-ui-gray-700;
    }

    &:focus-visible {
      color: $neeto-ui-white;
      box-shadow: 0 0 0 3px #e5e5e5;
    }
  }

  &-secondary {
    color: white;
    background-color: transparent;

    &:hover:not(:disabled),
    &:active {
      color: $neeto-ui-gray-800;
      background-color: $neeto-ui-gray-400;
    }

    &:focus-visible {
      color: $neeto-ui-gray-800;
      box-shadow: 0 0 0 3px #e5e5e5;
    }
  }

  &-text {
    color: $neeto-ui-gray-800;

    &:hover:not(:disabled),
    &:active {
      color: $neeto-ui-gray-800;
      background-color: $neeto-ui-gray-200;
    }

    &:focus-visible {
      color: $neeto-ui-gray-800;
      box-shadow: 0 0 0 3px #e5e5e5;
    }
  }

  &-link {
    color: $neeto-ui-secondary-indigo;
    min-height: initial;
    padding: 0;

    &:hover:not(:disabled),
    &:active,
    &:focus {
      opacity: 0.8;
      color: $neeto-ui-secondary-indigo;
    }

    &:focus-visible {
      color: darken($neeto-ui-secondary-indigo, 25%);
    }
  }

  &-danger {
    color: $neeto-ui-error;
    background-color: $neeto-ui-pastel-red;

    &:hover:not(:disabled),
    &:active {
      color: $neeto-ui-error;
      background-color: darken($neeto-ui-pastel-red, 2.5%);
    }

    &:focus-visible {
      color: $neeto-ui-error;
      box-shadow: 0 0 0 3px darken($neeto-ui-pastel-red, 2.5%);
    }
  }
}
