.tabs-height {
  @media screen and (min-width: 768px) {
    height: calc(100vh - 60px - 49px);
    max-height: 90vh;
  }
}
.home-height {
  @media screen and (min-width: 768px) {
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
  }
}

.confirm-height {
  @media screen and (min-width: 768px) {
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
  }
}

.input-box {
  background-color: transparent !important;
}
