.neeto-ui-dropdown__wrapper {
  &.neeto-ui-dropdown__wrapper--auto-width {
    ul {
      min-width: auto;
    }
  }
  &--multilevel{
    .neeto-ui-dropdown__popup{
      overflow-y: visible;
    }
  }
}

.neeto-ui-dropdown__popup {
  width: auto;
  min-width: 168px;
  max-height: 360px;
  overflow-y: auto;
  background-color: $neeto-ui-white;
  border-radius: $neeto-ui-rounded-sm;
  border: thin solid $neeto-ui-gray-100;
  box-shadow: $neeto-ui-shadow-md;
  padding: 4px 0;
  z-index: 99999;

  li,
  .neeto-ui-dropdown--item {
    display: flex;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    transition: $neeto-ui-transition;
    font-size: $neeto-ui-text-sm;
    font-weight: $neeto-ui-font-normal;
    color: $neeto-ui-gray-800;
    padding: 8px 40px 8px 16px;
    text-decoration: none;
    outline-color: transparent;

    &.disabled {
      cursor: not-allowed;
      color: $neeto-ui-gray-500;
    }

    &:hover,
    &:focus {
      background-color: $neeto-ui-gray-200;
    }

    &.loading:not(.active) {
      &:after {
        content: "";
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        display: block;
        border-radius: 50%;
        border: solid 2px $neeto-ui-gray-200;
        border-top-color: $neeto-ui-black;
        border-left-color: $neeto-ui-black;
        animation: theme("animation.spin");
      }
    }

    &.active {
      background-color: $neeto-ui-gray-800;
      color: $neeto-ui-white;
    }
  }
}
