.neeto-ui-callout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: $neeto-ui-rounded-sm;
  padding: 16px 12px;
  font-size: $neeto-ui-text-sm;
  line-height: 20px;
  color: $neeto-ui-gray-700;

  .neeto-ui-callout__icon {
    flex-shrink: 0;
    margin-right: 12px;
    color: $neeto-ui-gray-600;
  }

  &.neeto-ui-callout--info {
    background-color: $neeto-ui-pastel-blue;
  }
  &.neeto-ui-callout--warning {
    background-color: $neeto-ui-pastel-yellow;
  }
  &.neeto-ui-callout--danger {
    background-color: $neeto-ui-pastel-red;
  }
}
