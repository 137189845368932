.neeto-ui-date-input,
.ant-picker {
  width: 100%;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: $neeto-ui-text-sm;
  padding: 8px;
  line-height: 1.6;

  transition: $neeto-ui-transition;

  color: white;
  background-color: transparent;
  border: thin solid $neeto-ui-gray-400;
  border-radius: $neeto-ui-rounded-sm;

  overflow: hidden;

  &:hover {
    &:not(.neeto-ui-date-input--disabled, .neeto-ui-time-input--disabled) {
      border-color: $neeto-ui-gray-800 !important;
    }
  }

  &.neeto-ui-date-input--small {
    padding: 4px 8px;
    line-height: 1.3;
  }

  &:focus-within:not(.neeto-ui-date-input--naked, .neeto-ui-date-input--error) {
    border-color: $neeto-ui-gray-800;
    box-shadow: $neeto-ui-shadow;
  }

  &:hover:not(.neeto-ui-date-input--naked, .neeto-ui-date-input--error, .neeto-ui-date-input--disabled, .neeto-ui-time-input--disabled) {
    border-color: $neeto-ui-gray-700;
  }

  &.neeto-ui-date-input--error {
    border-color: $neeto-ui-error;

    &:focus-within:not(.neeto-ui-date-input--naked) {
      box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
    }
  }

  &.neeto-ui-date-input--disabled {
    background-color: $neeto-ui-gray-100;
    cursor: not-allowed;

    input {
      cursor: not-allowed;
    }
  }

  &.ant-picker-focused {
    &.neeto-ui-date-input--naked {
      border: none;
      box-shadow: none !important;
    }
  }

  &.neeto-ui-date-input--naked {
    border: none;
    box-shadow: none;

    .neeto-ui-date-input__prefix {
      margin-left: 0;
    }

    .neeto-ui-date-input__suffix {
      margin-right: 0;
    }
  }

  &.neeto-ui-date-input--error {
    border-color: $neeto-ui-error;

    &:focus-within:not(.neeto-ui-date-input--naked) {
      box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
    }
  }
}

.neeto-ui-time-input,
.ant-picker {
  padding: 8px;
  &.neeto-ui-time-input--small {
    padding: 4px 8px;
  }

  &:hover {
    &:not(.neeto-ui-time-input--disabled, .neeto-ui-date-input--disabled) {
      border-color: $neeto-ui-gray-800 !important;
    }
  }

  &:focus-within:not(.neeto-ui-time-input--naked, .neeto-ui-time-input--error) {
    border-color: $neeto-ui-gray-800;
    box-shadow: $neeto-ui-shadow;
  }

  &:hover:not(.neeto-ui-time-input--naked, .neeto-ui-time-input--error, .neeto-ui-time-input--disabled) {
    border-color: $neeto-ui-gray-700;
  }

  &.neeto-ui-time-input--error {
    border-color: $neeto-ui-error;

    &:focus-within:not(.neeto-ui-time-input--naked) {
      box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
    }
  }

  &.neeto-ui-time-input--disabled {
    background-color: $neeto-ui-gray-100;
    cursor: not-allowed;

    input {
      cursor: not-allowed;
    }
  }

  &.ant-picker-focused {
    &.neeto-ui-date-input--naked {
      border: none;
      box-shadow: none !important;
    }
  }

  &.neeto-ui-time-input--naked {
    border: none;
    box-shadow: none;

    .neeto-ui-time-input__prefix {
      margin-left: 0;
    }

    .neeto-ui-time-input__suffix {
      margin-right: 0;
    }
  }

  &.neeto-ui-time-input--error {
    border-color: $neeto-ui-error;

    &:focus-within:not(.neeto-ui-time-input--naked) {
      box-shadow: 0 0 0 3px $neeto-ui-pastel-red;
    }
  }
}

.ant-picker-time-panel {
  .ant-picker-time-panel-column {
    & > li.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: $neeto-ui-gray-800;
        color: $neeto-ui-white;
      }
    }
  }
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  background: $neeto-ui-gray-800;
}

.ant-picker-focused {
  border-color: $neeto-ui-gray-800 !important;
  box-shadow: $neeto-ui-shadow !important;
}

.ant-picker-dropdown,
.neeto-ui-date-input-dropdown {
  z-index: $neeto-ui-modal-z-index + 1;
  .ant-picker-panel {
    .ant-picker-header {
      .ant-picker-header-view {
        color: $neeto-ui-gray-600;

        button {
          &:hover {
            color: $neeto-ui-gray-800;
          }
        }
      }
    }

    .ant-picker-body {
      .ant-picker-content {
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
          .ant-picker-cell-inner {
            &::before {
              border: 1px solid $neeto-ui-gray-800;
            }
          }
        }

        .ant-picker-cell.ant-picker-cell-in-view {
          &::after {
            border-color: $neeto-ui-gray-400;
          }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
          .ant-picker-cell-inner {
            background: $neeto-ui-gray-800;
          }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
          &::before {
            background: $neeto-ui-gray-200;
          }

          .ant-picker-cell-inner {
            background: $neeto-ui-gray-800;
          }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
          &::before {
            background: $neeto-ui-gray-200;
          }
        }

        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start,
        .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
          &::before {
            background: $neeto-ui-gray-400;
          }

          .ant-picker-cell-inner {
            &::after {
              background: $neeto-ui-gray-400;
            }
          }
        }
      }
    }
  }

  .ant-picker-footer {
    .ant-picker-today-btn,
    .ant-picker-now-btn {
      color: $neeto-ui-gray-600;

      &:hover {
        color: $neeto-ui-gray-800;
      }
    }

    .ant-picker-ok {
      button {
        color: $neeto-ui-white;
        background: $neeto-ui-gray-800;
        border-color: $neeto-ui-gray-800;
      }
    }
  }
}
.ant-picker-clear {
  visibility: hidden !important;
}
