.neeto-ui-label {
  font-size: $neeto-ui-text-sm;
  line-height: 1;
  color: $neeto-ui-gray-600;
  font-weight: $neeto-ui-font-normal;
  letter-spacing: -0.15px;
  padding-bottom: 4px;
}

.neeto-ui-main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.neeto-ui-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 24px 2px rgba(0, 0, 0, 0.1);
}

.neeto-ui-main-scrollable {
  flex-grow: 1;
  overflow-y: auto;

  &.neeto-ui-main-scrollable--small {
    // height: calc(100vh - #{$neeto-ui-main-header-height} - #{$neeto-ui-sub-header-height});
  }
  &.neeto-ui-main-scrollable--large {
    // height: calc(100vh - #{$neeto-ui-main-header-height});
  }
  &.neeto-ui-main-scrollable--viewport {
    // height: 100vh;
  }
}
