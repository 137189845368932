.neeto-ui-modal__backdrop {
  position: fixed;
  z-index: $neeto-ui-modal-z-index;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  justify-content: center;
  align-items: center;

  .neeto-ui-modal__wrapper {
    width: 50%;
    max-width: 100%;
    position: relative;
    background-color: $neeto-ui-white;
    border-radius: $neeto-ui-rounded-sm;

    .neeto-ui-modal__close {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &.neeto-ui-modal__wrapper--xs {
      width: 320px;
    }

    &.neeto-ui-modal__wrapper--sm {
      width: 480px;
    }

    &.neeto-ui-modal__wrapper--md {
      width: 720px;
    }

    .neeto-ui-modal__header {
      padding: 56px 32px 8px;
    }

    .neeto-ui-modal__body {
      padding: 0 32px;
      font-size: $neeto-ui-text-sm;
      line-height: 1.5;
    }

    .neeto-ui-modal__footer {
      padding: 24px 32px 32px 32px;
    }

    .neeto-ui-alert__footer {
      padding: 24px 32px 32px 32px;
      display: flex;
    }
  }

  .neeto-ui-icon__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    padding: 10px 0 0 2px;
  }
}
