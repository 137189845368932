.neeto-ui-header {
  width: 100%;
  // min-height: $neeto-ui-main-header-height;
  background: $neeto-ui-white;
  padding: 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;

  .neeto-ui-header__left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .neeto-ui-header__breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    a {
      transition: all ease-in-out 300ms;
      text-decoration: none;
      h2 {
        color: $neeto-ui-gray-500;
        &:hover {
          color: $neeto-ui-gray-800;
        }
      }
    }
  }

  .neeto-ui-header__breadcrumb-separator {
    margin: 0 4px;
  }

  .neeto-ui-header__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.neeto-ui-subheader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: $neeto-ui-sub-header-height;
  margin-bottom: $neeto-ui-sub-header-bottom-margin;
  background-color: $neeto-ui-white;

  .neeto-ui-subheader__left-wrapper {
    display: flex;
    flex-grow: 1;

    .neeto-ui-subheader__left {
      width: 50%;
      max-width: 280px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .neeto-ui-input__prefix {
        color: $neeto-ui-gray-400;
      }
    }
  }


  .neeto-ui-subheader__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
