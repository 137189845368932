body {
  // color utility classes
  @each $color, $value in $neeto-ui-theme-colors {
    .neeto-ui-text-#{$color} {
      color: $value;
    }
    .hover\:neeto-ui-text-#{$color}:hover {
      color: $value;
    }
    .focus\:neeto-ui-text-#{$color}:focus {
      color: $value;
    }
  }
  @each $color, $value in $neeto-ui-theme-colors {
    .focus-visible\:neeto-ui-text-#{$color}:focus-visible {
      color: $value;
    }
  }

  // background utility classes
  @each $color, $value in $neeto-ui-theme-colors {
    .neeto-ui-bg-#{$color} {
      background-color: $value;
    }
    .hover\:neeto-ui-bg-#{$color}:hover {
      background-color: $value;
    }
    .focus\:neeto-ui-bg-#{$color}:focus {
      background-color: $value;
    }
  }
  @each $color, $value in $neeto-ui-theme-colors {
    .focus-visible\:neeto-ui-bg-#{$color}:focus-visible {
      background-color: $value;
    }
  }

  // border color
  @each $color, $value in $neeto-ui-theme-colors {
    .neeto-ui-border-#{$color} {
      border-color: $value;
    }
    .hover\:neeto-ui-border-#{$color}:hover {
      border-color: $value;
    }
    .focus\:neeto-ui-border-#{$color}:focus {
      border-color: $value;
    }
  }
  @each $color, $value in $neeto-ui-theme-colors {
    .focus-visible\:neeto-ui-border-#{$color}:focus-visible {
      border-color: $value;
    }
  }

  // box shadows
  @each $shadow, $value in $neeto-ui-shadows {
    .neeto-ui-shadow-#{$shadow} {
      box-shadow: $value;
    }
    .hover\:neeto-ui-shadow-#{$shadow}:hover {
      box-shadow: $value;
    }
    .focus\:neeto-ui-shadow-#{$shadow}:focus {
      box-shadow: $value;
    }
  }
  @each $shadow, $value in $neeto-ui-shadows {
    .focus-visible\:neeto-ui-shadow-#{$shadow}:focus-visible {
      box-shadow: $value;
    }
  }

  // font sizes
  @each $size, $value in $neeto-ui-font-sizes {
    .neeto-ui-text-#{$size} {
      font-size: $value;
    }
  }

  // font weights
  @each $weight, $value in $neeto-ui-font-weights {
    .neeto-ui-font-#{$weight} {
      font-weight: $value;
    }
  }

  // line height
  @each $lh, $value in $neeto-ui-line-heights {
    .neeto-ui-leading-#{$lh} {
      line-height: $value;
    }
  }

  // text transform
  @each $tt, $value in $neeto-ui-text-transform {
    .neeto-ui-text-transform-#{$tt} {
      text-transform: $value;
    }
  }
}

.neeto-ui-no-outline{
  outline: none !important;
}
