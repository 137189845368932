// Color palette

// primary
$neeto-ui-white: #ffffff;
$neeto-ui-black: #1b1f23;
// content
$neeto-ui-gray-800: #2f3941;
$neeto-ui-gray-700: #49545c;
$neeto-ui-gray-600: #fdfdfd;
$neeto-ui-gray-500: #87929d;
$neeto-ui-gray-400: #c2c8cc;
$neeto-ui-gray-300: #d8dcde;
$neeto-ui-gray-200: #e9ebed;
$neeto-ui-gray-100: #f8f9f9;
// states
$neeto-ui-success: #00ba88;
$neeto-ui-info: #276ef1;
$neeto-ui-warning: #f3cd82;
$neeto-ui-error: #f56a58;
// pastel
$neeto-ui-pastel-blue: #eaf3fc;
$neeto-ui-pastel-green: #ebf5ec;
$neeto-ui-pastel-yellow: #fff2d7;
$neeto-ui-pastel-red: #ffefed;
$neeto-ui-pastel-teal: #98f3f4;
// secondary
$neeto-ui-secondary-indigo: #5e5ce6;
$neeto-ui-secondary-green: #00ba88;
$neeto-ui-secondary-teal: #64d2ff;

// neeto-ui-theme-colors-map
$neeto-ui-theme-colors: (
  // primary
  "black": $neeto-ui-black,
  "white": $neeto-ui-white,
  // content
  "gray-800": $neeto-ui-gray-800,
  "gray-700": $neeto-ui-gray-700,
  "gray-600": $neeto-ui-gray-600,
  "gray-500": $neeto-ui-gray-500,
  "gray-400": $neeto-ui-gray-400,
  "gray-300": $neeto-ui-gray-300,
  "gray-200": $neeto-ui-gray-200,
  "gray-100": $neeto-ui-gray-100,
  // states
  "success": $neeto-ui-success,
  "info": $neeto-ui-info,
  "warning": $neeto-ui-warning,
  "error": $neeto-ui-error,
  // pastel
  "pastel-blue": $neeto-ui-pastel-blue,
  "pastel-green": $neeto-ui-pastel-green,
  "pastel-yellow": $neeto-ui-pastel-yellow,
  "pastel-red": $neeto-ui-pastel-red,
  "pastel-teal": $neeto-ui-pastel-teal,
  // secondary
  "secondary-indigo": $neeto-ui-secondary-indigo,
  "secondary-green": $neeto-ui-secondary-green,
  "secondary-teal": $neeto-ui-secondary-teal
);

// Font Weights
$neeto-ui-font-thin: 100;
$neeto-ui-font-extralight: 200;
$neeto-ui-font-light: 300;
$neeto-ui-font-normal: 400;
$neeto-ui-font-medium: 500;
$neeto-ui-font-semibold: 600;
$neeto-ui-font-bold: 700;
$neeto-ui-font-extrabold: 800;
$neeto-ui-font-black: 900;

// neeto-ui-font-weights-map
$neeto-ui-font-weights: (
  "thin": $neeto-ui-font-thin,
  "extralight": $neeto-ui-font-extralight,
  "light": $neeto-ui-font-light,
  "normal": $neeto-ui-font-normal,
  "medium": $neeto-ui-font-medium,
  "semibold": $neeto-ui-font-semibold,
  "bold": $neeto-ui-font-bold,
  "extrabold": $neeto-ui-font-extrabold,
  "black": $neeto-ui-font-black,
);

// Font Sizes
$neeto-ui-text-xxs: .75rem;
$neeto-ui-text-xs: .875rem;
$neeto-ui-text-sm: 1rem;
$neeto-ui-text-base: 1.25rem;
$neeto-ui-text-lg: 1.563rem;
$neeto-ui-text-xl: 1.953rem;
$neeto-ui-text-2xl: 2.441rem;
$neeto-ui-text-3xl: 3.052rem;
// $neeto-ui-text-4xl: 48px;

// neeto-ui-font-sizes-map
$neeto-ui-font-sizes: (
  "h1": $neeto-ui-text-3xl,
  "h2": $neeto-ui-text-2xl,
  "h3": $neeto-ui-text-xl,
  "h4": $neeto-ui-text-base,
  "h5": $neeto-ui-text-sm,
  "h6": $neeto-ui-text-xs,
  "body1": $neeto-ui-text-base,
  "body2": $neeto-ui-text-sm,
  "body3": $neeto-ui-text-xs,
);

// Line Heights
$neeto-ui-leading-none: 1;
$neeto-ui-leading-tight: 1.25;
$neeto-ui-leading-snug: 1.375;
$neeto-ui-leading-normal: 1.5;
$neeto-ui-leading-relaxed: 1.625;
$neeto-ui-leading-loose: 2;

// neeto-ui-line-heights-map
$neeto-ui-line-heights: (
  "none": $neeto-ui-leading-none,
  "tight": $neeto-ui-leading-tight,
  "snug": $neeto-ui-leading-snug,
  "normal": $neeto-ui-leading-normal,
  "relaxed": $neeto-ui-leading-relaxed,
  "loose": $neeto-ui-leading-loose,
);

// Text-transform
$neeto-ui-text-transform-none: none;
$neeto-ui-text-transform-capitalize: capitalize;
$neeto-ui-text-transform-uppercase: uppercase;
$neeto-ui-text-transform-lowercase: lowercase;
$neeto-ui-text-transform-fullwidth: full-width;
$neeto-ui-text-transform-inherit: inherit;
$neeto-ui-text-transform-initial: initial;
$neeto-ui-text-transform-revert: revert;
$neeto-ui-text-transform-unset: unset;

// neeto-ui-text-transform-map
$neeto-ui-text-transform: (
  "none": $neeto-ui-text-transform-none,
  "capitalize": $neeto-ui-text-transform-capitalize,
  "uppercase": $neeto-ui-text-transform-uppercase,
  "lowercase": $neeto-ui-text-transform-lowercase,
  "full-width": $neeto-ui-text-transform-fullwidth,
  "inherit": $neeto-ui-text-transform-inherit,
  "initial": $neeto-ui-text-transform-initial,
  "revert": $neeto-ui-text-transform-revert,
  "unset": $neeto-ui-text-transform-unset,
);

// Border Radius
$neeto-ui-rounded-none: 0;
$neeto-ui-rounded-sm: 2px;
$neeto-ui-rounded: 4px;
$neeto-ui-rounded-md: 6px;
$neeto-ui-rounded-lg: 8px;
$neeto-ui-rounded-xl: 10px;
$neeto-ui-rounded-full: 999px;

// Transition
$neeto-ui-transition: all 0.3s ease-in-out;

// Shadows
$neeto-ui-shadow: 0px 0px 0px 3px $neeto-ui-gray-200;
$neeto-ui-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

// Box Shadows
$neeto-ui-shadow-xs: 0px 1px 4px -1px rgba(28, 48, 74, 0.12);
$neeto-ui-shadow-s: 0px 3px 12px -1px rgba(28, 52, 84, 0.12),
  0px 2px 4px -1px rgba(28, 55, 90, 0.08);
$neeto-ui-shadow-m: 0px 8px 24px -4px rgba(28, 50, 79, 0.12),
  0px 2px 6px -1px rgba(28, 55, 90, 0.08);
$neeto-ui-shadow-l: 0px 12px 48px -6px rgba(28, 50, 79, 0.12),
  0px 3px 18px -2px rgba(28, 55, 90, 0.08);
$neeto-ui-shadow-xl: 0px 18px 64px -8px rgba(28, 50, 79, 0.12),
  0px 4px 24px -3px rgba(28, 55, 90, 0.08);

// neeto-ui-shadows-map
$neeto-ui-shadows: (
  "xs": $neeto-ui-shadow-xs,
  "s": $neeto-ui-shadow-s,
  "m": $neeto-ui-shadow-m,
  "l": $neeto-ui-shadow-l,
  "xl": $neeto-ui-shadow-xl,
);

// Breakpoints
$neeto-ui-mob-479: 479px;
$neeto-ui-tab-768: 768px;
$neeto-ui-tab-1024: 1024px;
$neeto-ui-desk-1200: 1200px;

// Constants
$neeto-ui-pane-header-height: 78px;
$neeto-ui-pane-footer-height: 80px;

$neeto-ui-main-header-height: 117px;
$neeto-ui-sub-header-height: 37px;
$neeto-ui-sub-header-bottom-margin: 20px;
$neeto-ui-pagination-block-height: 88px;
$neeto-ui-table-header-height: 40px;

// Z Indexes
$neeto-ui-modal-z-index: 99999;
