.neeto-ui-colorpicker__popover {
  width: 200px;
  padding: 4px 8px;

  .neeto-ui-colorpicker__saturation {
    width: 100%;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;

    .neeto-ui-colorpicker__pointer {
      width: 4px;
      height: 4px;
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 1.5px,
        rgba(0, 0, 0, 0.3) 0px 0px 1px 1px inset,
        rgba(0, 0, 0, 0.4) 0px 0px 1px 2px;
      border-radius: 50%;
      transform: translate(-2px, -2px);
    }
  }

  .neeto-ui-colorpicker__hue {
    height: 10px;
    margin: 12px 0;
    position: relative;

    .neeto-ui-colorpicker__slider {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: translate(-6px, -1px);
      background-color: rgb(248, 248, 248);
      box-shadow: rgba(0, 0, 0, 0.37) 0px 1px 4px 0px;
    }
  }

  .neeto-ui-colorpicker__input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: thin solid $neeto-ui-gray-200;
    border-radius: $neeto-ui-rounded-md;
    padding: 4px;

    input {
      width: 100%;
      outline-color: transparent;
      font-size: 14px;
      color: $neeto-ui-gray-800;
    }
  }
}

.neeto-ui-color-palette__item {
  width: 32px;
  height: 32px;
  border: thin solid transparent;
  transition: all 0.3s;
  padding: 2px;
  border-radius: $neeto-ui-rounded-md;
  margin: 0 4px 4px 0;

  &.active {
    border-color: $neeto-ui-gray-500;
  }

  div {
    width: 100%;
    min-height: 100%;
    border-radius: $neeto-ui-rounded-md;
    overflow: hidden;
  }
}

.neeto-ui-colorpicker__target {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $neeto-ui-gray-100;
  border-radius: $neeto-ui-rounded-md;
  width: 100%;
  height: 40px;

  .neeto-ui-colorpicker-target__color {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-shadow: $neeto-ui-shadow-md;
    border-radius: $neeto-ui-rounded-md;
    background-color: $neeto-ui-white;

    div {
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
  }

  .neeto-ui-colorpicker-target__code {
    padding: 10px 16px;
    font-size: 14px;
    color: $neeto-ui-gray-800;
    font-weight: $neeto-ui-font-medium;
  }
}
