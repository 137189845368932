//Button

.nui-btn {
  &.nui-btn--primary {
    background-color: theme("colors.yellow.1000") !important;

    &:hover:not(:disabled),
    &:active,
    &:focus {
      background-color: theme("colors.blue.600");
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px theme("colors.white"),
        0 0 0 4px theme("colors.blue.500");
    }
  }

  &.nui-btn--link {
    color: theme("colors.blue.500");

    &:hover:not(:disabled),
    &:active,
    &:focus {
      color: theme("colors.blue.600");
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px theme("colors.white"),
        0 0 0 4px theme("colors.blue.500");
    }
  }
}

//Form

.nui-input__wrapper {
  .nui-input {
    &:focus-within:not(.nui-input--naked, .nui-input--error) {
      box-shadow: 0 0 0 3px theme("colors.blue.100");
      border-color: theme("colors.blue.400");
    }
  }
}

.nui-radio[type="radio"],
.nui-checkbox[type="checkbox"] {
  color: theme("colors.blue.500");

  &:focus-visible {
    box-shadow: 0 0 0 2px theme("colors.white"),
      0 0 0 4px theme("colors.blue.500");
  }
}

.nui-switch__wrapper {
  .nui-switch__container {
    &.nui-switch__container--checked {
      background-color: theme("colors.blue.500");
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px theme("colors.white"),
        0 0 0 4px theme("colors.blue.500");
    }
  }
}

.nui-react-select-container {
  .nui-react-select__control.nui-react-select__control--is-focused {
    border: thin solid theme("colors.blue.400");
    box-shadow: 0 0 0 3px theme("colors.blue.100");
  }

  .nui-react-select__menu {
    .nui-react-select__option--is-selected {
      background-color: theme("colors.blue.500");
    }

    .nh_react-select__fixed-option {
      a {
        color: theme("colors.blue.500");
      }

      &:hover {
        a {
          color: theme("colors.blue.600");
        }
      }
    }
  }

  .nui-react-select__multi-value {
    background-color: theme("colors.blue.100");
    border: thin solid theme("colors.blue.400");
  }

  .nui-react-select__multi-value__remove {
    color: theme("colors.blue.500");
  }
}

.bp3-datepicker.bp3-daterangepicker.bp3-daterangepicker-contiguous.bp3-daterangepicker-single-month {
  .bp3-menu.bp3-daterangepicker-shortcuts {
    .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss.bp3-popover-dismiss-override {
      background-color: theme("colors.blue.500");
    }
  }
}

.DayPicker {
  .DayPicker-wrapper {
    .DayPicker-Months {
      .DayPicker-Month {
        .DayPicker-Body {
          .DayPicker-Week {
            .DayPicker-Day {
              &--selected {
                background-color: theme("colors.blue.500");

                &:active,
                &:hover {
                  background-color: theme("colors.blue.600");
                }
              }

              &--selected-range {
                background-color: theme("colors.blue.100");
                &:hover,
                &:active {
                  background-color: theme("colors.blue.200");
                }
              }
            }
          }
        }
      }
    }
  }
}

.nui-date-range-picker,
.nui-date-picker {
  .bp3-input-group.bp3-small .bp3-input,
  .bp3-input {
    &:focus,
    &:active {
      border-color: theme("colors.blue.400");
      box-shadow: 0 0 0 3px theme("colors.blue.100");
    }
  }
}

.nui-time-input__wrapper {
  .bp3-timepicker {
    .bp3-timepicker-input-row {
      &:focus-within {
        border-color: theme("colors.indigo.500");
        box-shadow: 0 0 0 3px theme("colors.indigo.100");
      }
    }
  }

  .bp3-html-select {
    select {
      &:focus-visible {
        box-shadow: 0 0 0 2px theme("colors.white"),
          0 0 0 4px theme("colors.indigo.500");
      }
    }
  }
}

.bp3-slider {
  .bp3-slider-progress.bp3-intent-primary {
    background-color: theme("colors.blue.500") !important;
  }

  .bp3-slider-handle {
    background: theme("colors.blue.500");
    box-shadow: 0 0 0 1px theme("colors.blue.500"),
      0 1px 1px theme("colors.blue.500");

    &:focus-visible {
      box-shadow: 0 0 0 2px theme("colors.white"),
        0 0 0 4px theme("colors.blue.500");
    }

    &:hover {
      background: theme("colors.blue.600");
    }
  }

  .bp3-slider-handle.bp3-active {
    background-color: theme("colors.blue.600");
  }
}

//Tab

.nui-tab--wrapper {
  .nui-tab {
    &:after {
      background-color: theme("colors.blue.600");
    }

    &.active {
      color: theme("colors.blue.600");
    }
  }
}

.neeto-ui-label {
  line-height: 1.3;
}

.neeto-ui-accordion__wrapper .neeto-ui-accordion__item {
  margin-top: 0;
}

.neeto-ui-checkbox__wrapper .neeto-ui-checkbox__container {
  border-radius: .25rem;
}

.ant-picker-header-view button {
  color: rgba(0, 0, 0, 0.85);
}

.headroom {
  z-index: 7 !important; //51 earlier
}

.align-center {
  align-items: center !important;
}
