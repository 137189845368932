// Ant Table overrides

.ant-table-wrapper {
  width: 100%;
  border-collapse: collapse;

  thead.ant-table-thead {
    tr {
      th {
        color: $neeto-ui-gray-500;
        background-color: $neeto-ui-white;

        font-size: $neeto-ui-text-xs;
        vertical-align: middle;
        font-weight: $neeto-ui-font-semibold;
        text-transform: uppercase;

        &::before {
          display: none;
        }
      }

      th.ant-table-cell.ant-table-column-has-sorters {
        &:hover {
          background-color: $neeto-ui-white;
        }

        .ant-table-column-sorters {
          .ant-table-column-sorter.ant-table-column-sorter-full {
            .ant-table-column-sorter-inner {
              .ant-table-column-sorter-up {
                color: $neeto-ui-gray-300;
              }

              .ant-table-column-sorter-down {
                color: $neeto-ui-gray-300;
              }

              .active {
                color: $neeto-ui-gray-800;
              }
            }
          }
        }
      }
    }
  }

  .ant-table-tbody>tr.ant-table-row-selected>td {
    background-color: $neeto-ui-pastel-blue;
  }

  .neeto-ui-table--row {
    color: $neeto-ui-gray-700;
    font-size: $neeto-ui-text-sm;
    font-weight: $neeto-ui-font-normal;
    vertical-align: middle;

    a:not(.neeto-ui-btn) {
      color: $neeto-ui-secondary-indigo;

      transition: $neeto-ui-transition;
      font-weight: $neeto-ui-font-medium;

      &:hover {
        color: darken($neeto-ui-secondary-indigo, 5%);

        text-decoration: underline;
      }
    }

    &.neeto-ui-table--row_hover{
      cursor: pointer;
    }
  }

  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    li.ant-pagination-item {
      &:hover {
        border-color: $neeto-ui-gray-700;

        a {
          color: $neeto-ui-gray-700;
        }
      }
    }

    li.ant-pagination-item.ant-pagination-item-active {
      border-color: $neeto-ui-gray-800;

      a {
        color: $neeto-ui-gray-800;
      }
    }

    .ant-pagination-prev:not(.ant-pagination-disabled),
    .ant-pagination-next:not(.ant-pagination-disabled) {
      &:hover {
        .ant-pagination-item-link {
          border-color: $neeto-ui-gray-800;

          .anticon.anticon-left,
          .anticon.anticon-right {
            color: $neeto-ui-gray-800;
          }
        }
      }
    }
  }

  .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
  .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {

    .anticon.anticon-double-right.ant-pagination-item-link-icon,
    .anticon.anticon-double-left.ant-pagination-item-link-icon {
      color: $neeto-ui-gray-800;
    }
  }

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th,
  .ant-table tfoot>tr>td,
  .ant-table tfoot>tr>th {
    padding: 12px 16px;
  }

  .ant-pagination-options {
    div.ant-select {
      &.ant-select-focused {
        .ant-select-selector {
          border-color: $neeto-ui-gray-800 !important;
          box-shadow: $neeto-ui-shadow;
          outline: none;

          &:focus,
          &:focus-visible,
          &:active {
            border-color: $neeto-ui-gray-800;
            box-shadow: $neeto-ui-shadow;
          }
        }
      }

      &:hover {
        .ant-select-selector {
          border-color: $neeto-ui-gray-800 !important;
          box-shadow: $neeto-ui-shadow;
          outline: none;

          &:focus,
          &:focus-visible,
          &:active {
            border-color: $neeto-ui-gray-800;
            box-shadow: $neeto-ui-shadow;
          }
        }
      }
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: $neeto-ui-gray-200;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: $neeto-ui-white;
    font-weight: inherit;
    background-color: $neeto-ui-gray-800;
  }

  .ant-spin-nested-loading {
    .ant-spin.ant-spin-spinning {
      .ant-spin-dot-item {
        background-color: $neeto-ui-gray-800;
      }
    }
  }
}