.nuifadeIn {
  animation: nuifadeIn 0.3s both;
}

@keyframes nuifadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
